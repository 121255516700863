import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Divider, Wrapper } from './styles'

const Card = () => {
  return (
    <>
      <Wrapper>
        <StaticImage src={'../../../images/package.jpg'} alt="Auto Transwell" />
        <div className="content">
          <h4>Logistyka kontraktowa</h4>
          <p>
            W ostatnich latach coraz częściej widać wsparcie kooperacyjne między
            firmami. Dla wielu przedsiębiorstw jest to szansa na rozwój. Dzięki
            takiemu podejściu Twoja firma może oferować kompleksowe usługi w
            danej branży, a jednocześnie specjalizować się w usłudze, która
            przynosi najwyższe zyski.
          </p>
          <p>
            W wielu firmach, szczególnie produkcyjnych, ale także
            kamieniarskich, meblowych i wielu innych, ogromnym problemem jest
            transport i logistyka. Logistyka wymaga niezbędnego zaplecza. Należy
            posiadać magazyn, samochody transportowe oraz, w wielu przypadkach,
            komputerowe systemy logistyczne. Jest to ogromny wydatek, który może
            nigdy się nie zwrócić. Jeśli potrzebujesz przewieźć coś raz czy dwa
            razy w tygodniu, to zwyczajnie nie opłaca się inwestować w auto oraz
            pracownika, który wykona tę pracę.
          </p>
          <p>
            Innym problemem jest magazynowanie towarów. Tutaj również wymagana
            jest ogromna inwestycja na zadaszone, a często także i ocieplane
            hale. Ponadto trzeba wymyślić cały system magazynowania oraz
            transportu tak, aby był logiczny, prosty w wykonaniu oraz opłacalny.
          </p>
          <p>
            Każda z tych rzeczy sprawia, że trudno jest zająć się logistyką w
            odpowiedni i skoordynowany sposób, tak aby było to opłacalne. A co,
            jeśli nie da się tego wykonać? Wtedy warto podpisać umowę na tak
            zwany outsourcing, czyli zlecić logistykę i transport zewnętrznej
            firmie.
          </p>
        </div>
      </Wrapper>
      <Divider />
      <Wrapper noMargin>
        <div className="content">
          <h4>
            Czy warto skorzystać z usług logistyki kontraktowej? Wady, zalety
            oraz możliwe korzyści
          </h4>
          <p>
            Pierwsze pytanie, które się nasuwa, to to, czy warto zlecić
            logistykę i transport zewnętrznej firmie i co mi da logistyka
            kontraktowa. Przede wszystkim dużo wolnego czasu i energii, którą
            będziesz mógł spożytkować na zajęcie się tymi gałęziami w firmie,
            które generują zarobki. W zależności od umowy firma zewnętrzna może
            zająć się dla Ciebie magazynowaniem, transportem oraz pełną
            logistyką produkcyjną czy transportem wewnętrznym.
          </p>
          <p>
            Firma logistyczna podejmie się wszystkich operacji prowadzonych na
            powierzchni magazynowej. Ponadto sprawa zarządzania łańcuchem dostaw
            przestanie zajmować Twoje myśli. Firma zewnętrzna zoptymalizuje całą
            logistykę w taki sposób, aby była ona najbardziej korzystna dla
            Ciebie.
          </p>
          <p>
            Logistyka kontraktowa zdejmie z Ciebie ciężar zakupu samochodów, co
            może wiązać się z oszczędnościami na kilkaset tysięcy złotych. Poza
            tym nie musisz zajmować się serwisem samochodów, nie dotyczą Cię
            żadne problemy drogowe, wypadki czy problemy z pracownikami. Od
            momentu podpisania umowy, powyższe problemy będą obchodzić już tylko
            firmę logistyczną.
          </p>
          <p>
            Kolejnym powodem, dla którego warto skorzystać z usług
            logistycznych, jest doświadczenie. Firma, która na co dzień zajmuje
            się logistyką, dba o klienta i jego towar, jest specjalistą w tej
            branży i prawdopodobnie zna dużo lepsze rozwiązania dla Twojej
            firmy. Dzięki temu usługa nie będzie tylko odciążeniem Cię z
            problemu logistyki, ale także może okazać się, że zewnętrzna firma i
            logistyka kontraktowa sprawią, że transport stanie się tańszy.
          </p>
        </div>
        <div className="images">
          <StaticImage
            src={'../../../images/transwell_kato.jpg'}
            alt="Auto Transwell"
          />
          <StaticImage
            src={'../../../images/transwell_unpacking.jpg'}
            alt="Auto Transwell"
          />
        </div>
      </Wrapper>
      <Divider />
      <Wrapper>
        <StaticImage
          src={'../../../images/transwell_cars_tires.jpg'}
          alt="Auto Transwell"
        />
        <div className="content">
          <h4>Logistyka kontraktowa outsourcing w Polsce – Transwell</h4>
          <p>
            Z punktu widzenia logistycznego należy minimalizować koszty i
            maksymalizować zyski. Zarówno w przypadku transportu liniowego, jak
            i w wypadku logistyki kontraktowej, firma zewnętrzna posiada
            odpowiednie możliwości, aby usprawnić cały proces transportu towarów
            do klientów.
          </p>
          <p>
            Firma Transwell specjalizuje się w nietypowych rozwiązaniach. Nasze
            usługi obejmują gałąź nietypowej logistyki. Zajmujemy się
            transportem specjalistycznym door to door oraz zleceniami z dostawą
            tego samego dnia. Poza tym współpracujemy z firmami produkcyjnymi, w
            których zajmujemy się logistyką i transportem wielu produktów i
            towarów. Poza tym jesteśmy gotowi podjąć się działania w każdej
            chwili. Jeśli w Twojej firmie brakuje zaplecza transportowego, a na
            już trzeba dostarczyć pilne zamówienie do Katowic, Gliwic, Łodzi,
            Krakowa, Opola czy Wrocławia, to koniecznie do nas zadzwoń!
          </p>
        </div>
      </Wrapper>
    </>
  )
}

export default Card
