/* eslint-disable react/jsx-pascal-case */

import React from 'react'
import Layout from 'src/hoc/Layout'
import Nav from 'components/Nav'
import SubPageHeader from 'components/SubPageHeader'

import ContractLogisticPage from 'components/ContractLogisticPage'
import SEO from 'components/SEO'

const ContractLogistics = props => {
  return (
    <Layout>
      <SEO />
      <Nav {...props} black />
      <SubPageHeader
        title="Co to jest logistyka kontraktowa?"
        imageName="WAREHOUSE"
      />
      <ContractLogisticPage />
    </Layout>
  )
}

export default ContractLogistics
