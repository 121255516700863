import React from 'react'
import Article from '../Article'
import { Wrapper } from './styles'
import Card from './Card'

const ContractLogisticPage = () => {
  return (
    <Article>
      <Wrapper>
        <p className="introduction">
          Zasadą działania i powodem istnienia firm jest generowanie coraz
          większych zysków. Jest to podstawowy cel, dla którego istnieją
          przedsiębiorstwa. Jest wiele sposobów, aby każda firma mogła przynosić
          coraz większe zyski. Można szukać nowych klientów, wprowadzać na rynek
          nowe rozwiązania lub optymalizować obecne systemy na różne sposoby.
          Jedną z fundamentalnych zasad prowadzenia interesów jest
          minimalizowanie pracy i maksymalizowanie zysków. Można to zrobić na
          różne sposoby. Inwestować w sprzęt, który pozwoli daną czynność
          wykonywać wielokrotnie szybciej, kupować maszyny wykonujące pracę
          wielu ludzi, do których obsługi wystarczy jeden lub dwóch pracowników,
          zlecać część prac i czynności zewnętrznym firmom.
        </p>
        <h3>
          Dostępność i gotowość 24h/365 dni w roku
          <br />
          <br />
          Zaangażowanie i indywidualne podejście do każdego klienta
        </h3>
        <p className="common">
          Oferujemy możliwość stałej współpracy z atrakcyjnymi rabatami dla
          firm.
        </p>
        <Card />
      </Wrapper>
    </Article>
  )
}

export default ContractLogisticPage
