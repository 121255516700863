import styled from 'styled-components'

export const Wrapper = styled.div`
  .introduction {
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 400;
    font-size: 16px;
    max-width: 90%;
    margin-bottom: 50px;
    @media only screen and (max-width: 1023px) {
      font-size: 14px;
      max-width: 100%;
      text-align: justify;
    }
  }
  h3 {
    text-align: center;
    font-size: 26px;
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 50px;
    br:nth-of-type(2) {
      @media only screen and (min-width: 1024px) {
        display: none;
      }
    }
  }
  .common {
    margin-bottom: 50px;
  }
`
